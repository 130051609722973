@media only screen and (min-width: 1261px) {
	#mobile-carousel {
		display: none;
	}
	.carousel {
		background-color: var(--carouselBackground);
		height: 85vh !important;
	}
	.carousel.carousel-slider.center.carousel_images {
		width: 100%;
		height: 85vh !important;
	}

	.carousel .carousel-item > img {
		width: 100%;
		height: 85vh !important;
		object-fit: fill;
		opacity: 1;
		aspect-ratio : auto !important;
	}
}

@media only screen and (max-width: 1260px) {
	#desktop-carousel {
		display: none;
	}
	.carousel {
		background-color: var(--carouselBackground);
		height: 60vh !important;
	}
	.carousel.carousel-slider.center.carousel_images {
		width: 100%;
		height: 60vh !important;
	}
	.carousel .carousel-item > img {
		width: 100%;
		height: 60vh !important;
		object-fit: fill;
	}
}

@media only screen and (max-width: 700px) {
	#desktop-carousel {
		display: none;
	}
	.carousel {
		background-color: var(--carouselBackground);
		height: 40vh !important;
	}
	.carousel.carousel-slider.center.carousel_images {
		width: 100%;
		height: 40vh !important;
	}
	.carousel .carousel-item > img {
		width: 100%;
		height: 40vh !important;
		object-fit: fill;
	}
}

.carousel .btn {
	background: var(--primaryDarker);
	box-shadow: 1px 1px 1px var(--primary);
}

.carousel .btn:hover {
	background: var(--primaryDarker);
}

/* Following is for animating the button, remove if not necessary */
.carousel .btn {
	animation: animate 2.5s infinite both;
}
@keyframes animate {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.08);
	}
	100% {
		transform: scale(1);
	}
}