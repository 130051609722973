.main {
	width: 80%;
	align-self: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 5%;
	padding-bottom: 5%;
	color: white;
	/* margin: 5% auto; */
	/* background-color: black; */
    /* background: linear-gradient(#000000, #000000de); */
}
.descContent{
    background: linear-gradient(#000000, #000000de);
}

.card {
	padding: 2.5%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.38);
    border-width: 1px;
    border-radius: 5px;
	/* filter: drop-shadow(0px -1px 5px rgba(233, 223, 255, 0.3)) drop-shadow(0px -1px 20px rgba(187, 155, 255, 0.15)); */
    
}

.heading {
	text-align: center;
	color: var(--primary);
	font-weight: bold !important;
	font-size: 300%;
	padding-bottom: 1%;
	background: linear-gradient(to right,#ce6363, #ad2dc7);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.headingSub {
	text-align: center;
	opacity: 0.9;
	font-weight: lighter !important;
	font-size: 150%;
}

.subheading {
	padding-top: 2%;
	font-weight: 1000;
	font-size: 150%;
	text-align: left;
	border-bottom: 1px rgba(255, 255, 255, 0.38) solid;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	margin-bottom: 1%;
	color: blue;
}

.subheadingtext {
	padding-bottom: 1%;
}

.imgDiv {
	padding-top: 2%;
	padding-bottom: 2%;
}
.imgSize {
	display: block;
	margin-left: auto;
	margin-right: auto;
	object-fit: contain;
	margin-top: 2%;
	border: 1px black solid;
}

@media only screen and (min-width: 1261px) {
	.imgSize {
		max-height: 75vh;
		min-height: 50vh;
		height: auto;
		max-width: 75vw;
		min-width: 40vw;
	}
}

@media only screen and (max-width: 1260px) {
	.imgSize {
		max-height: 60vh;
		max-width: 95vw;
		height: auto;
		min-height: 30vh;
		min-width: 60vw;
	}
}

.description {
	font-size: 100%;
	font-weight: 600;
}

.meet {
	color: blue;
	font-size: 100%;
	font-weight: 600;
	text-decoration: underline;
}

.membersList {
	list-style-type: disc;
}
.description table{
	/* border: 1px solid black; */
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.38);
    border-width: 1px;
    border-radius: 5px;
	width: 100%;
}
.description th{
	/* border: 2px 0px solid black ; */
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.38);
    border-width: 1px;
    border-radius: 5px;
}
.description tr{
	/* border-bottom: 1px solid black ; */
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.38);
    border-width: 1px;
    border-radius: 5px;
}