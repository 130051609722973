.main {
    --primary: #0c0013;
    --secondary: #eeeceb;
    --baseUnit: 10vmax;
    --boxShadowPrimary: #ffffff;
    /* background-color: var(--primary);
    background-image: url("../background.png"); */
    background-repeat: repeat;
    min-height: 90vh;
    padding-top: 170px;
    margin-top: -150px;
    padding-bottom: 10px;
}

.container {
    display: grid !important ;
    grid-template-columns: 25% 50% 25%;
    grid-template-areas: "a b c";
    padding-inline: 10%;
}

.mainContent {
    grid-area: b;
    display : flex;
    justify-content: center;
    align-items: center;
}


.input {
    grid-area: a !important;
    max-height: 100% !important;
    margin-top: 35% !important;
}
.input input {
    color: var(--secondary);
}


.output {
    color: var(--secondary);
    font-size: 16px;
    grid-area: c;
    max-height: 100%;
    margin-top: 35%;
}

.btn {
    margin-top: 30%;
    width: 100%;
    height : 100%;
    background-color: var(--secondary) !important;
    color: #0c0013 !important;
    padding : 2px;
    font-family: inherit;
    font-size : 18px !important;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #78e4ff, #ff48fa);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
}

.btn:hover {
    box-shadow: none !important;
    color: white !important; 
}

.btncon {
    width: 100%;
    padding-inline: 10%;
}



.heading,
.error {
    color: var(--secondary);
    font-size: 2em;
}

.error {
    padding-top: 2%;
}

.opresult {
    padding-top: 3%;
}

.image {
    height: 100%;
    width: 100%;
    z-index: 2;
    margin-bottom: 10%;
}
.tableMain {
    padding-left: 10%;
    padding-right: 15%;
    padding-top: 2%;
    padding-bottom: 2%;
}
.table {
    width: 100%;
    border: 1px solid black !important;
    border-collapse: collapse;
}
.table tr td,
.table tr th {
    text-align: center;
    border: 1px solid var(--secondary) !important;
    border-collapse: collapse;
    width: 53%;
    color: var(--secondary);
}

@font-face {
    font-family: ObsidianFont;
    src: url("../../../constants/customFont.TTF") format('truetype');
    font-weight: normal;
    font-style: normal;
}
.title * {
    padding-bottom: 2%;
    margin-inline: 10%;
    color: var(--secondary);
    text-align: start;
    font-size: 1.5rem;
    font-family: ObsidianFont;
}


.tabHead {
    padding-top: 1%;
    margin-bottom: calc(var(--baseUnit) * 0.5);
}
@media only screen and (max-width: 1260px) {
    .btn {
        font-size: 0.8rem;
        line-height: 1.5rem;
        height: min-content;
    }
}

@media only screen and (max-width: 700px){
    .btn{
        padding : 5px;
    }
    .container {
        display: grid !important ;
        grid-template-columns: 50%;
        align-items: center;
        justify-content: center;
        
        grid-template-areas: 
            "a" 
            "b" 
            "c" ;
        
    }
    .output{
        margin-top : 2%;
    }
}
