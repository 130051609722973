@media only screen and (min-width: 1261px) {
	.cardImage {
		max-height: 35vh;
	}
	.projectCard {
		padding: 0%;
	}
	.cardImage {
		padding-top: 5%;
		padding-bottom: 5%;
		width: 95%;
	}
}

@media only screen and (max-width: 1260px) {
	.cardImage {
		width: 80%;
		padding-top: 10%;
		padding-bottom: 5%;
	}

	.projectCard {
		width: 90%;
		margin-left: 5%;
	}
}
@media only screen and (min-width: 500px) and (max-width:1000px) {
	/* .row{
		display : flex;
		align-items : center;
		justify-content : center;
		flex-direction : row !important;
	} */
	.cardImage {
		width: 60%;
		padding-top: 10%;
		padding-bottom: 5%;
	}

	.projectCard {
		width: 70%;
		margin-left: 15%;
		/* margin : 20% !important; */
	}
}




.cardImage {
	aspect-ratio: 1/1 !important;
}
.descCard {
	padding-left: 5%;
	font-weight: lighter;
}

.detButton {
	padding-top: 2%;
}

.btnColor {
	background-color: var(--primary);
	border-radius: 10%;
	transition-duration: 0.4s;
}

.btnColor:hover {
	background-color: var(--primary);
	opacity: 0.75;
}
