.footer .page-footer {
  background-color: var(--primary);
}
.crypt {
  background-color: #0c0013 !important;
  background-image: url("../components/Transcend/Cryptonite/background.png");
}
.cryptonite {
  background-color: #0c0013 !important;
  /* background-image: url("../components/Transcend/Cryptonite/background.png"); */
}
.obsidian{
  background-color: #000 !important;

}
.obscura {
  background-color: #0c0013 !important;
  background-image: linear-gradient(315deg, #12a389 0%, #e83e7d 74%);
}
.charge {
  background-color: #00405b !important;
}

.she {
  background-color: #EA1B91 !important;
}
.footer .anchor {
  padding-left: 30px;
}

.footer .text {
  color: var(--secondary);
}
.crypt.footer .text {
  color: #60fb52;
}
.crypt.footer .white-text {
  color: #60fb52 !important;
}
