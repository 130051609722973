.register {
	animation: animate 2.5s infinite both;
	margin-bottom: 20px;
}
@keyframes animate {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.07);
	}
	100% {
		transform: scale(1);
	}
}
