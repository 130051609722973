@media only screen and (min-width: 601px) and (max-width: 1260px) {
    .coreImageDisplay.container {
        width: 80%;
        border-radius: 0;
    }
}

@media only screen and (min-width: 1261px) {
    .coreImageDisplay.container {
        width: 80%;
        border-radius: 0;
    }
}

.coreImageDisplay .core_img {
    border-radius: 100%;
    display: flex;
    flex: 0 0 8em;
    height: 8em;
    justify-content: center;
    overflow: hidden;
    position: relative;
    flex-direction: row;
    width: 8em;
    margin: 2%;
}

.coreImageDisplay .core_img img {
    height: 100%;
}

.coreImageDisplay .img__overlay {
    align-items: center;
    flex-direction: row;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: opacity 0.25s;
    z-index: 1;
}

.coreImageDisplay .img__overlay:hover {
    opacity: 1;
}

.coreImageDisplay .img__overlay {
    background-color: var(--ImageOverlayBackground);
    color: var(--ImageOverlayText);
    font-size: 15px;
}

.coreImageDisplay .flexrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
