.btn .btnDec {
    background-color: #000 !important;
    color: var(--secondary) !important;
}

.btnIn {
    width: 100%;
    background-color: var(--secondary)!important;
    color: var(--primary)!important;
}

.btnIn:hover {
    background-color : #000 !important;
    color: var(--secondary) !important;
    border : 1px solid #fff !important;
}

.btnDec {
    width: 100%;
    border : 1px solid #fff !important;
}

.mainBtn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 10%;
    padding-right: 10%;
}
.mainBtn > * {
    margin: 1%;
    flex-grow: 1;
}
