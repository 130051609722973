:root {
    --primary: #1a237e;
    --primaryDarker: #10154d;
    --secondary: #ffffff;
    --navBarPrimary: rgba(0, 0, 0, 0.5);
    --navBarPri: rgba(0, 0, 0, 0.85);
    --navBarSecondary: #ffffff;
    --carouselBackground: black;
    --coreImageh3: #ccc;
    --ImageOverlayBackground: black;
    --ImageOverlayText: whitesmoke;
    --projectDetailsBorder: black;
    --projectDetailsBackground: white;
    --sigCardReveal: rgba(0, 0, 0, 0.7);
    --sigCardBorder: white;
    --sigCardText: white;
    --QuestionItemBackground: rgba(12, 27, 236, 0.479);
}

* {
    font-family: 'Montserrat', sans-serif;
}

pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
main {
    flex: 1 0 auto;
}
a.primary-link > * {
    vertical-align: middle;
}
a.primary-link,
button.waves-effect,
i.waves-effect {
    background-color: var(--primary);
}
a.primary-link:hover,
button.waves-effect:hover,
i.waves-effect:hover {
    background-color: var(--primary);
}

h3,
h2 {
    color: var(--primary);
    text-align: center;
}
.container {
    width: 100%;
}
.app-main {
    min-height: 66vh;
}
@media only screen and (min-width: 1261px) {
    .container {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 1260px) {
    .container {
        width: 90%;
        height: 90%;
    }
}
