.container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0 2vw;
}

@media screen and (min-width: 1000px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 3vw;
    }
}
