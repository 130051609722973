.leaderboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: 0 auto;
}

.leaderboard-title {
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #343a40;
    width: 100%;
    text-align: center;
}

.leaderboard-table {
    width: 100%;
    border-collapse: collapse;
}

.leaderboard-table th,
.leaderboard-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

.leaderboard-table th {
    background-color: #007bff;
    color: white;
}

.leaderboard-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.leaderboard-table tr:hover {
    background-color: #ddd;
}
