.sigcard .card .card-reveal {
  background-color: var(--sigCardReveal);
}
.sigcard .card .card-reveal .card-title {
  padding-bottom: 3%;
}

.sigcard .card .card-reveal hr {
  border-bottom: 0.2vh solid var(--sigCardBorder);
  opacity: 0.7;
}

.sigcard .card .card-reveal p {
  padding-top: 4%;
  opacity: 0.7;
}

.sigcard .card .card-reveal a {
  background-color: var(--primary);
  /* padding: 2%; */
  color: var(--sigCardText);
}
.sigcard .card:hover{
  cursor: pointer;
}